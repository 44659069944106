<template>
  <div v-if="isShow && isVisible"
    :class="['fixed z-50 px-4 py-3 rounded-xl border-opacity-80 backdrop-blur-md flex items-center gap-3 max-w-96 transition ease-in-out duration-100', `${positionClass}`, isDark ? `bg-black border border-black` : `bg-white border border-gray-100 `]">
    <div v-if="type === 'warning'"
      class="icon h-8 w-8 flex items-center justify-center rounded-full bg-yellow-400 bg-opacity-20 flex-shrink-0">
      <Icon name="mynaui:info-triangle" class="text-yellow-500" size="22" />
    </div>
    <div v-else-if="type === 'info'"
      class="icon h-8 w-8 flex items-center justify-center rounded-full bg-blue-400 bg-opacity-20 flex-shrink-0">
      <Icon name="mynaui:info-circle" class="text-blue-500" size="22" />
    </div>
    <div v-else-if="type === 'success'"
      class="icon h-8 w-8 flex items-center justify-center rounded-full bg-green-400 bg-opacity-20 flex-shrink-0">
      <Icon name="mynaui:check-waves" class="text-green-500" size="22" />
    </div>
    <div v-else-if="type === 'error'"
      class="icon h-8 w-8 flex items-center justify-center rounded-full bg-secondary bg-opacity-20 flex-shrink-0">
      <Icon name="mynaui:octagon-x" class="text-secondary" size="22" />
    </div>
    <div :class="['content', isDark ? 'text-white' : 'text-black']">
      <p v-if="title" class="mb-1">{{ title }}</p>
      <p v-if="description" class="text-[13px] md:text-sm">{{ description }}</p>
    </div>
  </div>
</template>

<script setup>

// const props = defineProps(['position', 'title', 'description', 'type', 'isDark', 'isShow']);
const props = defineProps({
  duration: {
    type: Number,
    default: 3000,
  },
  position: String,
  title: String,
  description: String,
  type: String,
  isDark: Boolean,
  isShow: Boolean,
});
let positionClass = ref('');

switch (props.position) {
  case 'top-left':
    positionClass = 'top-8 left-8';
    break;
  case 'bottom-left':
    positionClass = 'bottom-8 left-8';
    break;

  case 'top-center':
    positionClass = 'top-8 left-1/2 -translate-x-1/2';
    break;
  case 'bottom-center':
    positionClass = 'bottom-8 left-1/2 -translate-x-1/2';
    break;

  case 'top-right':
    positionClass = 'top-8 right-8';
    break;
  case 'bottom-right':
    positionClass = 'bottom-8 right-8';
    break;

  case 'left-center':
    positionClass = 'top-1/2 left-8 -translate-y-1/2';
    break;
  case 'right-center':
    positionClass = 'top-1/2 right-8 -translate-y-1/2';
    break;

  default:
    positionClass = 'top-8 right-8';
}

import { ref, onMounted, onUnmounted } from 'vue';

const isVisible = ref(true);

const errorStore = useErrorStore()

onMounted(() => {
  const timer = setTimeout(() => {
    isVisible.value = false;
    errorStore.showError('', false, '')
  }, props.duration);

  onUnmounted(() => {
    clearTimeout(timer);
  });
});

</script>
